/* React Calendar */
.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  line-height: 1.125em;
	border: none;
	@apply font-plex text-black rounded-md !important;
}
.react-calendar__navigation__prev2-button {
	@apply rounded-tl-md !important;
}
.react-calendar__navigation__next2-button {
	@apply rounded-tr-md !important;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
	@apply text-mid-grey bg-light-grey !important;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  @apply bg-kato-100 text-white !important;
}
.react-calendar__tile--now {
  background: black;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	background: black;
}
.react-calendar__tile--hasActive {
  @apply bg-kato-100 !important;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  @apply bg-kato-100 !important;
}
.react-calendar__tile--active {
  background: black;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  @apply bg-kato-100 !important;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  @apply bg-kato-100 !important;
}

/* React Clock */
.react-clock {
  display: block;
  position: relative;
}
.react-clock,
.react-clock *,
.react-clock *:before,
.react-clock *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-clock__face {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid black;
  border-radius: 50%;
}
.react-clock__hand {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}
.react-clock__hand__body {
  position: absolute;
  background-color: black;
  transform: translateX(-50%);
}
.react-clock__mark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}
.react-clock__mark__body {
  position: absolute;
  background-color: black;
  transform: translateX(-50%);
}
.react-clock__mark__number {
  position: absolute;
  left: -40px;
  width: 80px;
  text-align: center;
}
.react-clock__second-hand__body {
  background-color: red;
}

/* React DateTime Picker */
.react-datetime-picker {
	display: inline-flex;
	position: relative;
	@apply font-plex text-xl !important;
}
.react-datetime-picker, .react-datetime-picker *, .react-datetime-picker *:before, .react-datetime-picker *:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
.react-datetime-picker--disabled {
	background-color: #f0f0f0;
	color: #6d6d6d;
}
.react-datetime-picker__wrapper {
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
  color: white;
  border-radius: 6px;
  padding: 4px;
  @apply bg-dark-grey;
}
.react-datetime-picker__inputGroup {
	flex-grow: 1;
	padding: 0 2px;
}
.react-datetime-picker__inputGroup__divider {
	padding: 1px 0;
	white-space: pre;
}
.react-datetime-picker__inputGroup__input {
	min-width: 0.54em;
	height: calc(100%);
	position: relative;
	padding: 1px;
	border: 0;
	background: none;
	font: inherit;
	box-sizing: content-box;
	-moz-appearance: textfield;
}
.react-datetime-picker__inputGroup__input::-webkit-outer-spin-button, .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.react-datetime-picker__inputGroup__input:invalid {
	background: rgba(255,0,0,0.1);
}
.react-datetime-picker__inputGroup__input--hasLeadingZero {
	margin-left: -0.54em;
	padding-left: calc(1px + 0.54em);
}
.react-datetime-picker__inputGroup__amPm {
	font: inherit;
	-moz-appearance: menulist;
}
.react-datetime-picker__button {
	border: 0;
	background: transparent;
	padding: 10px;
}
.react-datetime-picker__button:enabled {
	cursor: pointer;
}
.react-datetime-picker__button svg {
	@apply stroke-white !important;
}
.react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon, .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
	@apply stroke-kato-100 !important;
}
.react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
	@apply stroke-white !important;
}
.react-datetime-picker__button svg {
	display: inherit;
}
.react-datetime-picker__calendar, .react-datetime-picker__clock {
	z-index: 1;
}
.react-datetime-picker__calendar--closed, .react-datetime-picker__clock--closed {
	display: none;
}
.react-datetime-picker__calendar {
	width: 350px;
	max-width: 100vw;
}

.react-datetime-picker__calendar .react-calendar {
	border-width: thin;
}

.react-datetime-picker__clock {
		width: 200px;
		height: 200px;
		max-width: 100vw;
		padding: 25px;
		background-color: white;
		border: thin solid #a0a096;
}

.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
	@apply bg-black text-white !important;
}

.react-datetime-picker__calendar-button {
	display: none;
}

.react-datetime-picker__button {
	@apply ml-auto !important;
}

.react-datetime-picker {
	@apply w-full !important;
}

/* Date range picker styles */

.react-daterange-picker {
  display: block !important;
}

.react-daterange-picker__calendar {
  position: unset !important;
  @apply w-full !important;
}

.react-daterange-picker__calendar .react-calendar {
  @apply font-plex mt-4 w-full !important;
}

.react-daterange-picker__wrapper {
  @apply border-none !important;
}

.react-daterange-picker__calendar--closed {
  @apply block !important;
}

.react-calendar__tile--active {
  @apply bg-kato-100 !important;
}

.react-calendar__tile--now {
  @apply bg-kato-25 !important;
}

.react-daterange-picker__calendar-button.react-daterange-picker__button {
  @apply hidden !important;
}

.react-daterange-picker__inputGroup {
  @apply w-max min-w-[unset] grow-0 !important;
}

.react-daterange-picker__range-divider {
  @apply mx-4 !important;
}

.react-daterange-picker__clear-button.react-daterange-picker__button {
  @apply ml-auto;
}

.react-calendar__tile--hover {
  @apply text-white bg-kato-100 !important;
}

.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__hour {
  @apply pl-8;
}

.react-daterange-picker__wrapper {
  @apply w-full flex;
}
