.horizontal-slider {
  @apply w-full h-10 m-auto px-4;
}
.example-thumb {
  @apply absolute z-[100] flex justify-center text-white shadow-md border border-yammy-100 rounded-md bg-yammy-100 cursor-pointer;
}
.example-thumb.active {
  @apply border-kato-100;
}
.example-track {
  position: relative;
  @apply bg-mid-grey;
}
.example-track.example-track-0 {
  @apply bg-kato-100
}
.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}
.horizontal-slider .example-thumb {
  top: 0px;
  width: 40px;
  outline: none;
  height: 40px;
  line-height: 38px;
}

.horizontal-slider.double-slider .example-track.example-track-0 {
  @apply bg-mid-grey
}

.horizontal-slider.double-slider .example-track.example-track-1 {
  @apply bg-kato-100
}

.horizontal-slider.double-slider .example-track.example-track-2 {
  @apply bg-mid-grey
}